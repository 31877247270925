import {
	HTTP_INTERCEPTORS,
	HttpClient,
	HttpClientModule
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
	indexedDBLocalPersistence,
	initializeAuth,
	provideAuth
} from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import {
	MAT_RIPPLE_GLOBAL_OPTIONS,
	MatNativeDateModule
} from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import {
	MAT_SNACK_BAR_DEFAULT_OPTIONS,
	MatSnackBarModule
} from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
	API_URL_TOKEN,
	AuthInterceptor
} from '@array-app/frontend/authentication';
import { VERSION_TOKEN } from '@array-app/frontend/common';
import { HttpLoaderFactory } from '@array-app/frontend/i18n';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { NgxUiLoaderModule, SPINNER } from 'ngx-ui-loader';
import packageJson from '../../../../package.json';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// For whatever reason these modules produce errors sometimes in lazy-loaded modules
const MatModules = [
	MatMenuModule,
	MatTooltipModule,
	MatNativeDateModule,
	MatDialogModule,
	MatSnackBarModule
];

@NgModule({
	declarations: [AppComponent],
	imports: [
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideAuth(() =>
			initializeAuth(getApp(), {
				persistence: indexedDBLocalPersistence
			})
		),
		provideFirestore(() => getFirestore()),
		provideStorage(() => getStorage()),
		provideAnalytics(() => getAnalytics()),
		BrowserModule,
		RouterModule,
		AppRoutingModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		NgxUiLoaderModule.forRoot({
			fgsType: SPINNER.circle,
			overlayColor: '#ffffff05'
		}),
		BrowserAnimationsModule,
		QuillModule.forRoot({
			placeholder: '',
			modules: {
				toolbar: [
					['bold', 'italic', 'underline', 'strike'],
					['blockquote', 'code-block'],
					[{ list: 'ordered' }, { list: 'bullet' }],
					['link'],
					['clean']
				]
			}
		}),
		...MatModules
	],
	bootstrap: [AppComponent],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: { duration: 5000 }
		},
		{ provide: API_URL_TOKEN, useValue: environment.firebaseConfig.apiUrl },
		{ provide: VERSION_TOKEN, useValue: packageJson.version },
		{
			provide: MAT_RIPPLE_GLOBAL_OPTIONS,
			useValue: {
				disabled: true,
				animation: { enterDuration: 0, exitDuration: 0 }
			}
		}
	]
})
export class AppModule {}
