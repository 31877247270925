// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	firebaseConfig: {
		apiKey: 'AIzaSyA90NeGGIyL609yEv8EcZgPfxy8ggY7AA0',
		authDomain: 'array-app-dev.firebaseapp.com',
		projectId: 'array-app-dev',
		storageBucket: 'array-app-dev.appspot.com',
		messagingSenderId: '758144240724',
		appId: '1:758144240724:web:7d9f39f1c9b1a6e42871c1',
		apiUrl: 'https://us-central1-array-app-dev.cloudfunctions.net/api'
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
