import { NgModule } from '@angular/core';
import { AuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import {
	AdminRoleGuard,
	AppUserDataResolve,
	OwnerRoleGuard,
	SuperRoleGuard
} from '@array-app/frontend/users';
import { NavTemplateComponent } from './shared';

const routes: Routes = [
	{
		path: 'docs',
		loadChildren: () =>
			import('./pages/docs/docs.module').then((m) => m.DocsModule)
	},
	{
		path: 'invite',
		resolve: [AppUserDataResolve],
		loadChildren: () =>
			import('./pages/invite/invite.module').then((m) => m.InviteModule)
	},
	{
		path: 'app',
		component: NavTemplateComponent,
		canActivate: [AuthGuard],
		resolve: [AppUserDataResolve],
		data: {
			authGuardPipe: () => redirectUnauthorizedTo(['login'])
		},
		children: [
			{
				path: 'dashboard',
				loadChildren: () =>
					import('./pages/dashboard/dashboard.module').then(
						(m) => m.DashboardModule
					)
			},
			// {
			// 	path: 'external-links',
			// 	loadChildren: () =>
			// 		import('./pages/external-links/external-links.module').then(
			// 			(m) => m.ExternalLinksModule
			// 		)
			// },
			// {
			// 	path: 'analytics',
			// 	data: {
			// 		authGuardPipe: () => redirectUnauthorizedTo(['login'])
			// 	},
			// 	loadChildren: () =>
			// 		import('./pages/analytics/analytics.module').then(
			// 			(m) => m.AnalyticsModule
			// 		)
			// },
			{
				path: 'products',
				canActivate: [AdminRoleGuard],
				loadChildren: () =>
					import('./pages/products/products.module').then(
						(m) => m.ProductsModule
					)
			},
			// {
			// 	path: 'categories',
			//  canActivate: [AdminRoleGuard],
			// 	loadChildren: () =>
			// 		import('./pages/categories/categories.module').then(
			// 			(m) => m.CategoriesModule
			// 		)
			// },
			{
				path: 'users',
				canActivate: [AdminRoleGuard],
				loadChildren: () =>
					import('./pages/users/users.module').then(
						(m) => m.UsersModule
					)
			},
			{
				path: 'groups',
				canActivate: [AdminRoleGuard],
				loadChildren: () =>
					import('./pages/groups/groups.module').then(
						(m) => m.GroupsModule
					)
			},
			{
				path: 'tags',
				canActivate: [AdminRoleGuard],
				loadChildren: () =>
					import('./pages/tags/tags.module').then((m) => m.TagsModule)
			},
			{
				path: 'reports',
				canActivate: [AdminRoleGuard],
				loadChildren: () =>
					import('./pages/reports/reports.module').then(
						(m) => m.ReportsModule
					)
			},
			{
				path: 'organization',
				canActivate: [OwnerRoleGuard],
				loadChildren: () =>
					import(
						'./pages/organizations/organization/organization.module'
					).then((m) => m.OrganizationModule)
			},
			{
				path: 'organizations',
				canActivate: [SuperRoleGuard],
				loadChildren: () =>
					import('./pages/organizations/organizations.module').then(
						(m) => m.OrganizationsModule
					)
			},
			{
				path: 'profile',
				loadChildren: () =>
					import('./pages/profile/profile.module').then(
						(m) => m.ProfileModule
					)
			},
			{
				path: '**',
				redirectTo: 'dashboard'
			}
		]
	},
	{
		path: '',
		loadChildren: () =>
			import('./pages/auth/auth.module').then((m) => m.AuthModule)
	},
	{
		path: '**',
		redirectTo: ''
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
