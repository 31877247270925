{
	"name": "array-app",
	"version": "2.7.2",
	"license": "MIT",
	"scripts": {
		"ng": "nx",
		"postinstall": "node ./decorate-angular-cli.js",
		"test": "nx test",
		"---- DEVELOPMENT": "",
		"start": "nx serve portal --configuration development",
		"start:viewer": "nx start model-viewer --configuration development",
		"start:native": "nx start native --configuration development",
		"start:api": "nx serve api --configuration development",
		"start:scripts": "nx serve scripts --environment=development",
		"start:test": "nx e2e portal-e2e --configuration development --watch",
		"build": "nx build portal --configuration development",
		"build:native": "nx build native --configuration development",
		"build:api": "nx build api --configuration development",
		"deploy:hosting": "npx firebase use dev && yarn run build && npx firebase deploy --only hosting",
		"deploy:api": "npx firebase use dev && yarn run build:api && npx firebase deploy --only functions",
		"deploy:rules": "npx firebase use dev && npx firebase deploy --only firestore:rules",
		"---- QA": "",
		"start:qa": "nx serve portal --configuration qa",
		"start:viewer:qa": "nx start model-viewer --configuration qa",
		"start:native:qa": "nx start native --configuration qa",
		"start:api:qa": "nx serve api --configuration qa",
		"start:scripts:qa": "nx serve scripts --environment=qa",
		"start:test:qa": "nx e2e portal-e2e --configuration qa --watch",
		"build:qa": "nx build portal --configuration qa",
		"build:native:qa": "nx build native --configuration qa",
		"build:api:qa": "nx build api --configuration qa",
		"deploy:hosting:qa": "npx firebase use qa && yarn run build:qa && npx firebase deploy --only hosting",
		"deploy:api:qa": "npx firebase use qa && yarn run build:api:qa && npx firebase deploy --only functions",
		"deploy:rules:qa": "npx firebase use qa && npx firebase deploy --only firestore:rules",
		"---- PRODUCTION": "",
		"start:prod": "nx serve portal --configuration production",
		"start:viewer:prod": "nx start model-viewer --configuration production",
		"start:native:prod": "nx start native --configuration production",
		"start:api:prod": "nx serve api --configuration production",
		"start:scripts:prod": "nx serve scripts --environment=production",
		"build:prod": "nx build portal --configuration production",
		"build:native:prod": "nx build native --configuration production",
		"build:api:prod": "nx build api --configuration production",
		"deploy:hosting:prod": "npx firebase use prod && yarn run build:prod && npx firebase deploy --only hosting",
		"deploy:api:prod": "npx firebase use prod && yarn run build:api:prod && npx firebase deploy --only functions",
		"deploy:rules:prod": "npx firebase use prod && npx firebase deploy --only firestore:rules",
		"---- MISC": "",
		"translations": "node tools/scripts/auto-order-translations.js"
	},
	"engines": {
		"node": "20"
	},
	"private": true,
	"pre-commit": [
		"translations"
	],
	"dependencies": {
		"@angular/animations": "17.1.3",
		"@angular/cdk": "17.1.2",
		"@angular/common": "17.1.3",
		"@angular/compiler": "17.1.3",
		"@angular/core": "17.1.3",
		"@angular/fire": "^17.0.1",
		"@angular/forms": "17.1.3",
		"@angular/material": "17.1.2",
		"@angular/platform-browser": "17.1.3",
		"@angular/platform-browser-dynamic": "17.1.3",
		"@angular/router": "17.1.3",
		"@babylonjs/core": "^6.43.0",
		"@babylonjs/gui": "^6.43.0",
		"@babylonjs/gui-editor": "^6.43.0",
		"@babylonjs/loaders": "^6.43.0",
		"@babylonjs/materials": "^6.43.0",
		"@babylonjs/react-native": "^1.8.2",
		"@babylonjs/react-native-iosandroid-0-71": "1.8.2",
		"@babylonjs/serializers": "^6.43.0",
		"@expo/metro-config": "0.17.4",
		"@firebase/app-compat": "0.2.25",
		"@firebase/app-types": "0.9.0",
		"@nestjs/axios": "3.0.1",
		"@nestjs/common": "10.2.7",
		"@nestjs/core": "10.2.7",
		"@nestjs/microservices": "10.2.7",
		"@nestjs/platform-express": "10.2.7",
		"@nestjs/websockets": "10.2.7",
		"@ngx-translate/core": "^14.0.0",
		"@ngx-translate/http-loader": "^7.0.0",
		"@nx/angular": "18.0.4",
		"@sendgrid/mail": "^7.7.0",
		"algoliasearch": "^4.14.3",
		"axios": "1.6.7",
		"browser-image-compression": "^2.0.0",
		"date-fns": "^2.29.1",
		"expo": "50.0.1",
		"expo-splash-screen": "0.26.4",
		"expo-status-bar": "1.11.1",
		"firebase": "^9.8.1",
		"firebase-admin": "^11.5.0",
		"firebase-functions": "^4.2.1",
		"json2csv": "^5.0.7",
		"ngx-color-picker": "^12.0.1",
		"ngx-image-cropper": "^6.2.2",
		"ngx-quill": "^24.0.4",
		"ngx-ui-loader": "^13.0.0",
		"quill": "^1.3.7",
		"react": "18.2.0",
		"react-dom": "18.2.0",
		"react-native": "0.73.4",
		"react-native-permissions": "^3.10.1",
		"react-native-web": "0.19.10",
		"reflect-metadata": "^0.1.13",
		"request": "^2.88.2",
		"rxjs": "7.8.1",
		"tslib": "^2.3.0",
		"ua-parser-js": "^1.0.2",
		"webpack": "5.89.0",
		"zone.js": "0.14.4"
	},
	"devDependencies": {
		"@angular-devkit/build-angular": "17.1.4",
		"@angular-devkit/core": "17.1.4",
		"@angular-devkit/schematics": "17.1.4",
		"@angular-eslint/eslint-plugin": "17.0.1",
		"@angular-eslint/eslint-plugin-template": "17.0.1",
		"@angular-eslint/template-parser": "17.0.1",
		"@angular/cli": "~17.1.0",
		"@angular/compiler-cli": "17.1.3",
		"@angular/language-service": "17.1.3",
		"@babel/core": "^7.14.5",
		"@babel/preset-react": "^7.14.5",
		"@config-plugins/detox": "~6.0.0",
		"@expo/cli": "0.16.8",
		"@expo/metro-runtime": "~3.1.1",
		"@nestjs/schematics": "10.0.2",
		"@nestjs/testing": "10.2.7",
		"@nx/cypress": "18.0.4",
		"@nx/detox": "18.0.4",
		"@nx/eslint": "18.0.4",
		"@nx/eslint-plugin": "18.0.4",
		"@nx/expo": "18.0.4",
		"@nx/jest": "18.0.4",
		"@nx/js": "18.0.4",
		"@nx/nest": "18.0.4",
		"@nx/node": "18.0.4",
		"@nx/react-native": "18.0.4",
		"@nx/web": "18.0.4",
		"@nx/webpack": "18.0.4",
		"@nx/workspace": "18.0.4",
		"@react-native-community/cli-platform-android": "12.3.0",
		"@react-native/babel-preset": "^0.73.18",
		"@react-native/metro-config": "0.73.5",
		"@schematics/angular": "17.1.4",
		"@swc-node/register": "1.8.0",
		"@swc/core": "~1.3.85",
		"@testing-library/jest-dom": "5.16.5",
		"@testing-library/jest-native": "~5.4.3",
		"@testing-library/react-native": "12.4.3",
		"@types/jest": "29.4.0",
		"@types/json2csv": "^5.0.3",
		"@types/node": "^18.16.9",
		"@types/quill": "^2.0.9",
		"@types/react": "18.2.56",
		"@types/react-dom": "^18.0.11",
		"@types/ua-parser-js": "^0.7.36",
		"@typescript-eslint/eslint-plugin": "6.21.0",
		"@typescript-eslint/parser": "6.21.0",
		"babel-jest": "^29.4.1",
		"babel-preset-expo": "10.0.1",
		"babylonjs-gltf2interface": "^6.43.0",
		"cypress": "^13.0.0",
		"detox": "20.18.1",
		"eslint": "8.48.0",
		"eslint-config-prettier": "9.0.0",
		"eslint-plugin-cypress": "2.15.1",
		"eslint-plugin-import": "2.27.5",
		"eslint-plugin-jsx-a11y": "6.7.1",
		"eslint-plugin-react": "7.32.2",
		"eslint-plugin-react-hooks": "4.6.0",
		"expo-modules-autolinking": "^1.5.1",
		"firebase-tools": "^13.0.2",
		"jest": "29.4.3",
		"jest-circus": "^29.4.1",
		"jest-environment-jsdom": "29.4.3",
		"jest-environment-node": "^29.4.1",
		"jest-expo": "50.0.2",
		"jest-preset-angular": "13.1.4",
		"jest-react-native": "18.0.0",
		"jsonc-eslint-parser": "^2.1.0",
		"nx": "18.0.4",
		"pre-commit": "^1.2.2",
		"prettier": "^3.1.0",
		"react-native-svg": "14.1.0",
		"react-native-svg-transformer": "1.2.0",
		"react-test-renderer": "18.2.0",
		"ts-jest": "29.1.1",
		"ts-node": "10.9.1",
		"typescript": "5.3.3",
		"yargs": "^17.7.2"
	}
}
