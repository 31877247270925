import { Component } from '@angular/core';
import { AuthService } from '@array-app/frontend/authentication';
import { DestroyService } from '@array-app/frontend/common';
import { Organization } from '@array-app/shared/types';
import {
	getAutoTheme,
	getDisplayTheme,
	getTheme,
	hexToHSL,
	setTheme
} from '@array-app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { DefaultLanguage } from './shared';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [DestroyService]
})
export class AppComponent {
	constructor(
		private readonly translate: TranslateService,
		private readonly authService: AuthService,
		private readonly destroy$: DestroyService
	) {
		const code = localStorage.getItem('lang');
		this.translate.setDefaultLang(DefaultLanguage.code);
		this.translate.use(code || DefaultLanguage.code);

		const theme = getTheme();
		if (theme === 'auto') {
			if (window.matchMedia) {
				const colorSchemeQuery = window.matchMedia(
					'(prefers-color-scheme: dark)'
				);
				colorSchemeQuery.addEventListener('change', () => {
					this.setOrganizationBranding();
					setTheme(getAutoTheme());
				});
			}
		}

		setTheme(theme);

		this.authService.organization$
			.pipe(takeUntil(this.destroy$))
			.subscribe((org) => {
				if (org) {
					this.setOrganizationBranding(org);
				} else {
					this.removeOrganizationBranding();
				}
			});
	}

	setOrganizationBranding(org?: Organization) {
		if (!org) {
			org = this.authService.organization$?.value;
		}

		if (org) {
			const displayTheme = getDisplayTheme();
			const hsl = hexToHSL(org[displayTheme].primaryColor);
			document.body.style.setProperty(
				'--color-primary-hs',
				`${hsl.h}, ${hsl.s}%`
			);
			document.body.style.setProperty('--color-primary-l', `${hsl.l}%`);
			document.body.style.setProperty(
				'--color-secondary',
				org[displayTheme].secondaryColor
			);
			document.body.style.setProperty(
				'--color-tertiary',
				org[displayTheme].tertiaryColor
			);
		}
	}

	removeOrganizationBranding() {
		document.body.style.removeProperty('--color-primary-hs');
		document.body.style.removeProperty('--color-primary-l');
		document.body.style.removeProperty('--color-secondary');
		document.body.style.removeProperty('--color-tertiary');
	}
}
